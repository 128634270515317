@import "../../styles/main.less";

.contact {
  .contact__form {
    input.ant-input, 
    textarea.ant-input {
      padding: 1rem 1.5rem;
      border-radius: 4px;

      &:focus {
        background-color: fade(@primary-color, 5%); 
      }
    }
  }

  .contact__img {
    .flex-center();

    img {
      max-width:  350px;
      width: 100%;
    }
  }
}

@primary-color: #3ecf8e;