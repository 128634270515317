@import '~antd/dist/antd.less';
@import './styles/main.less';

html {
  scroll-behavior: smooth;
}

main {
  background: white;
}

body {
  font-family: @primary-font;
}
@primary-color: #3ecf8e;