@import "../../../styles/main.less";

.about__card {
  .card();
  padding: 1rem !important;

  &:hover {
    border: 1px solid @primary-color !important;
  }

  .ant-card-cover {
    padding-top: 2.5rem;

    .about__card-icon {
      border: none;
    }
  }

  .ant-card-body {
    .ant-card-meta-description {
      margin-bottom: 2rem;
    }
  }
}
@primary-color: #3ecf8e;