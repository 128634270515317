@import "../../styles/main.less";

.section-heading {
  .flex-center();
  flex-direction: column;
  
  .section-heading__main {
    .heading();
  }

  .section-heading__sub {
    .sub-heading();
  }
}

@primary-color: #3ecf8e;