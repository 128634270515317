@import "../../styles/main.less";

.app-footer {
  background-color: @footer-color !important;
  padding: 0 !important;

  .app-footer__content {
    padding-bottom: 5rem;

    .app-footer__logo {
      .flex-center();
      justify-content: flex-start;
    }

    .app-footer__subscribe {
      .app-footer__subscribe-title {
        color: white;
        font-weight: bold;
        margin-bottom: 1.5rem;
      }

      .app-footer__subscribe-text {
        color: white; 
        font-size: 1rem;
        margin-bottom: 1.5rem;
      }

      .app-footer__subscribe-input {
        .ant-input {
          padding: 0.5rem;
          color: white;
          font-style: italic;
          background-color: lighten(@footer-color, 10%);
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          border-right: 0;
        }
    
        .ant-input-group-addon {
          color: white;
          border-color: @primary-color;
          background-color: @primary-color;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }

  .app-footer__copyright {
    color: white;
    font-size: 1.1rem;
    text-align: center;
    padding: 1.5rem 0;
    border-top: 1px solid lighten(@footer-color, 20%);
  }
}
@primary-color: #3ecf8e;