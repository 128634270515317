@import "../../styles/main.less";

.app-header {
  .flex-center();
  padding: 0 !important;
  height: @header-size !important;
  background: @gradient-bg !important;
  z-index: 99;

  .app-header__content {
    .flex-center();
    padding: 0 0 0 15px;

    .app-header__logo {
      height: 60px;
    }

    .app-header__menu {
      width: 780px;
      margin-left: auto;
      color: white;
      font-size: medium;
      font-weight: bold;
    
      background: transparent;
      border-bottom: none;
      line-height: 45px;

      text-align: right;

      .app-header__menu-icon {
        font-size: 1.5rem;
        transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        margin: auto;
    
        &:hover {
          color: @primary-color !important;
        }
      }

      .ant-menu-item {
        .app-header__menu-item {
          color: white;
        }
      } 

      .ant-menu-item-selected  {
        .app-header__menu-item {
          color: #3ecf8e;
        }
      }
    }
  }
}

@primary-color: #3ecf8e;