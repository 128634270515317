.container {
  width: 100%;
  padding: 7rem 15px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;

  &--fluid {
    max-width: none !important;
  }
}

@media only screen and (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@primary-color: #3ecf8e;