@import "../../styles/main.less";

.showcase {
  overflow: hidden;

  .showcase__heading {
    & > * {
      color: white;
    }
  }
  
  .showcase__container {
    background: @gradient-bg;
  }

  .showcase__slider {
    position: relative;
    margin: -8rem 2rem 8rem 2rem;
    overflow: auto;
    
    &::-webkit-scrollbar {
      height: 0.8rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: fade(#536cfc, 90%);
    }

    .ant-row {
      width: 1900px;

      .slider-item {
        position: relative;
        cursor: pointer;

        &:hover {
          .slider-item__info {
            opacity: 1;
          }
        }

        .slider-item__img {
          width: 250px;
        }

        .slider-item__info {
          .flex-center();
          flex-direction: column;
          opacity: 0;

          position: absolute;
          top: 1rem;
          left: 1rem;
          width: calc(100% - 2rem);
          height: calc(100% - 2rem);
          background-color: fade(@primary-color, 80%);
          transition: 0.5s all ease-in-out;

          .slider-item__name {
            color: white;
            font-weight: bold;
            font-size: 1.5rem;
          }

          .slider-item__icon {
            color: white;
            font-size: 1.5rem;
            transition: 0.3s all ease-in-out;
            cursor: pointer;

            &:hover {
              color: @font-color;
            }
          }
        }
      }
    }
  }
}
@primary-color: #3ecf8e;