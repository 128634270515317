@import "../../styles/main.less";

.services {
  background-color: #F9F9F9;

  .services__content {
    .ant-card-body {
      padding: 0 !important;
    }
  }
}
@primary-color: #3ecf8e;