@import "../../styles/main.less";

.business-plan {
  .business-plan__container {
    padding-top: 0;
  }

  .business-plan__title {
    .heading();
    text-align: left;
    line-height: 2.5rem;
    margin-bottom: 1.5rem !important;
  }

  .business-plan__desc {
    .text();
    text-align: left;
  }

  .business-plan__img {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
@primary-color: #3ecf8e;