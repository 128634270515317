@import "./variables";

.flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading() {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;

  @media only screen and (min-width: 576px) {
    & {
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }
  }
}

.sub-heading() {
  color: @font-color;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 3rem;
  max-width: 600px;

  @media only screen and (min-width: 576px) {
    & {
      font-size: 1.2rem;
    }
  }
}

.text() {
  font-size: 1rem;
  color: @font-color;
}

.icon() {
  .flex-center();
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: lighten(@primary-color, 35%);

  font-size: 2.5rem;
  color: @primary-color;
}

.card() {
  padding: 2rem 0.5rem;
  text-align: center;
  border: @border !important;
  transition: 0.5s all ease-in-out;

  .ant-card-meta-title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .ant-card-meta-description {
    .text();
  }
}