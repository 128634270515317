@import "../../styles/main.less";

.home {
  position: relative;
  background: @gradient-bg;
  overflow: hidden;
  display: flex;
  align-items: center;
  
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    border-top: 15vw solid transparent;
    border-right: 100vw solid white;
    pointer-events: none;
  }

  .home__container {
    padding-top: 5rem;

    .home__intro {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .intro__heading {
        color: white;
        font-size: 2rem;
        font-weight: bold;
        text-transform: capitalize;
      }
  
      .intro__sub-heading {
        color: white;
        font-size: 1.2rem;
      }

      .intro__button-group {
        margin-top: 1.5rem;
        min-width: 200px;
      }
    }

    .home__img-wrapper {
      .flex-center();

      .home__img {
        width: 100%;
        height: auto;
        margin: 2.5rem auto 2rem auto;
        z-index: 2;
      }
    }
  }
}


@media only screen and (min-width: 1200px) {
  .home {
    &::before{
      content: "";
      position: absolute;
      left: 5%;
      bottom: 0;
  
      width: 30vw;
      height: 15vh;
      background: @gradient-bg;
      transform: skewY(-10deg);
    }
  }
}
@primary-color: #3ecf8e;