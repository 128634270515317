@import "../../styles/main.less";

.app-icon {
  .flex-center();
  display: inline-flex !important;
  background-color: lighten(@primary-color, 38%) !important;
  color: @primary-color !important;
  border: 1px solid #3ecf8e;
  width: 100px !important;
  height: 100px !important;

  .anticon {
    font-size: 2.5rem;
  }

}
@primary-color: #3ecf8e;